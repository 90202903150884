
import React, { useState, useRef, useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { Link } from "react-router-dom";
import { endPoints } from "../../Contant/Environment";
import { useSelector, useDispatch } from "react-redux";
import { Actions } from "../../redux/Actions/Actions";
import { addItem } from "../../Utility/Api";
import { useNavigate } from "react-router-dom";
import Toaster from "../Toast/index";
import Loader from "../Loader/index";
import { ErrorToast, SuccesToast } from "../Toast/message";

function Login({ handleSecondModalOpen,handleSecondModalClose }) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [message, setMessage] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [validated, setValidated] = useState(false);
    const [isLoading, setIsLoadinig] = useState(false);
    const state = useSelector((state) => state);

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
      
        if (form.checkValidity() === false) {
          event.stopPropagation();
          setValidated(true);
          return;
        }
      
        const data = {
          username: email,
          password: password,
        };
      
        if (email && password) {
            
            try {
            const url = `${endPoints.api.SenderLogin}`;
            setIsLoadinig(true);
      
            const response = await addItem(url, data);
            // console.log(response)
            if (response.status === 200) {
            localStorage.setItem("session", response.token);
            handleSecondModalClose();

                setIsLoadinig(false); 
                dispatch({
                    type: Actions.SENDERLOGIN,
                    data: { sender: response.sender},
                });
                SuccesToast(response.message)
            
            }
      
            
          } catch (error) {
            setIsLoadinig(false);
            ErrorToast(
              "Issue connecting to the server (error code 15). Please contact support for further assistance."
            );
            console.error(error);
          }
        } else {
          setIsLoadinig(false);
          ErrorToast("All fields are required");
        }
      };
      
      

    return (
        <main className="bg-white">
            <section className="sec-padd login-page">
                <div className="container">
                    <h4 className="c-heading text-center">
                        Logged In To Stay In Touch
                    </h4>
                </div>

                <Row className="work-box rounded-0 border-0 mt-5">
                    <Tabs
                        defaultActiveKey="first"
                        className="align-items-center justify-content-center d-flex"
                    >
                        <Tab eventKey="first" title="For Sender">
                            <Row className="align-items-center justify-content-center mt-5">
                                <Col md={10}>

                                    <Form validated={validated}
                                        onSubmit={handleSubmit} className="Reative">
                                        {/* <Loader spinner={true} visible={true} className="LoaderForm" /> */}
                                        {isLoading && <Loader spinner={true} visible={isLoading} className="LoaderForm" />}
                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                            <Toaster />
                                        </div>
                                        <Form.Group
                                            className="mb-3"
                                            controlId="formBasicEmail"
                                        >
                                            <Form.Control
                                                type="email"
                                                placeholder="Enter User Name*"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </Form.Group>
                                        <Form.Group
                                            className="mb-3"
                                            controlId="formBasicPassword"
                                        >
                                            <Form.Control
                                                type="password"
                                                placeholder="Enter Password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                            />
                                        </Form.Group>
                                        <Form.Text className="text-dark justify-content-end d-flex">
                                            Forgot Password?
                                        </Form.Text>
                                        <div className="justify-content-center d-flex">
                                            <Button
                                                to="/"
                                                variant="primary"
                                                type="submit"
                                                className="log-btn"
                                                onClick={handleSubmit}
                                            >
                                                Log In
                                            </Button>
                                        </div>
                                        <Form.Text className="text-dark justify-content-center d-flex mt-5">
                                            Dont have an account?
                                            <span
                                                className="loginf-sender"
                                                onClick={handleSecondModalOpen}
                                            >
                                                Register Now
                                            </span>
                                        </Form.Text>
                                    </Form>
                                </Col>
                            </Row>
                        </Tab>
                        <Tab eventKey="second" title="For Traveler">
                            <Row className="align-items-center justify-content-center mt-5">
                                <Col md={10}>
                                    <Form>
                                        <Form.Group
                                            className="mb-3"
                                            controlId="formBasicEmail"
                                        >
                                            <Form.Control
                                                type="email"
                                                placeholder="Enter User Name*"
                                            />
                                        </Form.Group>
                                        <Form.Group
                                            className="mb-3"
                                            controlId="formBasicPassword"
                                        >
                                            <Form.Control
                                                type="password"
                                                placeholder="Enter Password"
                                            />
                                        </Form.Group>
                                        <Form.Text className="text-dark justify-content-end d-flex">
                                            Forgot Password?
                                        </Form.Text>
                                        <div className="justify-content-center d-flex">
                                            <Link
                                                to="/"
                                                variant="primary"
                                                type="submit"
                                                className="log-btn"
                                            >
                                                Log In
                                            </Link>
                                        </div>
                                        <Form.Text className="text-dark justify-content-center d-flex mt-5">
                                            Dont have an account?
                                            <span
                                                className="loginf-sender"
                                                onClick={handleSecondModalOpen}
                                            >
                                                Register Now
                                            </span>
                                        </Form.Text>
                                    </Form>
                                </Col>
                            </Row>
                        </Tab>
                    </Tabs>
                </Row>
            </section>
        </main>
    )
}

export default Login