import axios from 'axios'; 

import { endPoints } from "../Contant/Environment";
export const BASE_URL =  endPoints.apiBaseUrl;  
     
export const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
      // SessionId: localStorage.getItem('session')
    //'Access-Control-Allow-Origin': '*',
   // Authorization: 'Bearer ' + localStorage.getItem('caroktajwt')
  }
});
 