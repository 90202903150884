import axios from 'axios';
import { endPoints } from '../Contant/Environment';

// Set the base URL for your API
const baseURL = endPoints.apiBaseUrl;

// Create an instance of Axios with a base URL and custom headers
const api = axios.create({
  baseURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export const axiosInstance = axios.create({
  baseURL: baseURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
      // SessionId: localStorage.getItem('session')
    //'Access-Control-Allow-Origin': '*',
   // Authorization: 'Bearer ' + localStorage.getItem('caroktajwt')
  }
});

// Axios interceptor to handle errors globally
// api.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     console.error('API Error:', error);
//     return Promise.reject(error);
//   }
// );

// Helper function for adding a new item
export const addItem = async (endpoint, requestBody) => {
  try {
    const response = await api.post(endpoint, requestBody);
    return response.data;
  } catch (error) {
    console.error('Error adding item:', error);
    throw error;
  }
};

// Helper function for updating an existing item
export const updateItem = async (endpoint, requestBody) => {
  try {
    const response = await api.put(endpoint, requestBody);
    return response.data;
  } catch (error) {
    console.error('Error updating item:', error);
    throw error;
  }
};

// Helper function for deleting an item
export const deleteItem = async (endpoint) => {
  try {
    const response = await api.delete(endpoint);
    return response.data;
  } catch (error) {
    console.error('Error deleting item:', error);
    throw error;
  }
};

// Helper function for getting all items
export const getAllItems = async (endpoint) => {
  try {
    const response = await api.get(endpoint);
    return response.data;
  } catch (error) {
    console.error('Error getting items:', error);
    throw error;
  }
};
