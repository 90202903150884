// import './App.css';
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import { Outlet } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import React, { useState, Suspense, useEffect } from "react";
import { Actions } from "./redux/Actions/Actions";
import { axiosInstance, BASE_URL } from "../src/Utility/AxiosInstance"; // Import named exports

import { createBrowserHistory } from "history";

function App() {
  const state = useSelector((state) => state);
  const { auth, sidebar } = state;
  const [isLoading, setIsLoading] = useState(false);
  const history = createBrowserHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    axiosInstance.interceptors.request.use((config) => {
      config.headers["SessonId"] = localStorage.getItem("session");
      return config;
    });

    axiosInstance.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        console.log("error", error);
        if (error.response.status === 401) {
          window.alert(
            "You are currently logged in on another device. Please login again to continue.  You will be logged out of your other device."
          );
          history.replace("/login");
          dispatch({
            type: Actions.LOGOUT,
          });
          sessionStorage.clear();
          window.location.reload();
        }
        return Promise.reject(error);
      }
    );
  }, [dispatch, history]);

  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
}

export default App;
