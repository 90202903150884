import React from "react";
import { Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { Link } from "react-router-dom";
function Register() {
  return (
    <>
      <main className="bg-white">
        <section className="sec-padd login-page">
          <Row className="work-box rounded-0 border-0 ">
            <Tabs
              defaultActiveKey="first"
              className="align-items-center justify-content-center d-flex"
            >
              <Tab eventKey="first" title="Sender Register">
                <Row className="align-items-center justify-content-center mt-5">
                  <Col md={10}>
                    <Form>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control
                          type="email"
                          placeholder="Enter Your First Name*"
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                      >
                        <Form.Control
                          type="password"
                          placeholder="Enter Your Last Name"
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                      >
                        <Form.Control
                          type="password"
                          placeholder="Your Email Address*"
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                      >
                        <Form.Control
                          type="password"
                          placeholder="Mobile Number*"
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                      >
                        <Form.Control
                          type="password"
                          placeholder="User Name*"
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                      >
                        <Form.Control type="password" placeholder="Password" />
                      </Form.Group>
                      <div className="justify-content-center d-flex">
                        <Link
                          to="/"
                          variant="primary"
                          type="submit"
                          className="log-btn"
                        >
                          Register
                        </Link>
                      </div>
                    </Form>
                  </Col>
                </Row>
              </Tab>
              <Tab eventKey="second" title="Traveler Register">
                <Row className="align-items-center justify-content-center mt-5">
                  <Col md={10}>
                    <Form>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control
                          type="email"
                          placeholder="Enter Your First Name*"
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                      >
                        <Form.Control
                          type="password"
                          placeholder="Enter Your Last Name"
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                      >
                        <Form.Control
                          type="password"
                          placeholder="Your Email Address*"
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                      >
                        <Form.Control
                          type="password"
                          placeholder="Mobile Number*"
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                      >
                        <Form.Control
                          type="password"
                          placeholder="User Name*"
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                      >
                        <Form.Control type="password" placeholder="Password" />
                      </Form.Group>
                      <div className="justify-content-center d-flex">
                        <Link
                          to="/"
                          variant="primary"
                          type="submit"
                          className="log-btn"
                        >
                          Register
                        </Link>
                      </div>
                    </Form>
                  </Col>
                </Row>
              </Tab>
            </Tabs>
          </Row>
        </section>
      </main>
    </>
  );
}

export default Register;
