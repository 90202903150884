import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { PiAirplaneTakeoffFill } from "react-icons/pi";
import { PiAirplaneLandingFill } from "react-icons/pi";
import Card from "react-bootstrap/Card";
import { FaCalendarAlt } from "react-icons/fa";
import { BsFillSuitcase2Fill } from "react-icons/bs";
import { IoIosSettings } from "react-icons/io";
import Form from "react-bootstrap/Form";
import { IoIosArrowForward } from "react-icons/io";
import Dropdown from "react-bootstrap/Dropdown";
import { InputGroup } from "react-bootstrap";
import { ImAddressBook } from "react-icons/im";
import { IoSettingsSharp } from "react-icons/io5";
import { IoIosPhonePortrait } from "react-icons/io";
import { Link } from "react-router-dom";

function Dashboard() {
  return (
    <>
      <section className="sec-padd middle">
        <Container>
          <Row className=" g-5 dashboard-page">
            <Col md={4}>
              <div className="bg-white">
                <div className="card1 mb-4">
                  <Card.Img
                    variant="top"
                    src={require("../../Assets/Images/aman-dash.webp")}
                    alt="logo1"
                  />
                  <h6>Aman Diwakar</h6>
                </div>
                <div className="pb-5 pt-3 px-4">
                  <Row className=" justify-content-center">
                    <Col xs={12} lg={8}>
                      <div className="mb-5 text-center ">
                        <Link to="/" className="add">Add A Parcel</Link>
                      </div>
                    </Col>
                  </Row>
                  <h3 className="c-heading text-center mb-5">
                    Details Do Complete
                  </h3>
                  <div className="mb-5 pb-5">
                    <InputGroup className="mb-3 inpgrp">
                      <InputGroup.Text id="inputGroup-sizing-default">
                        <IoSettingsSharp />
                      </InputGroup.Text>
                      <Form.Control
                        aria-label="Default"
                        aria-describedby="inputGroup-sizing-default"
                        placeholder="General"
                      />
                    </InputGroup>
                    <InputGroup className="mb-3 inpgrp">
                      <InputGroup.Text id="inputGroup-sizing-default">
                        <ImAddressBook />
                      </InputGroup.Text>
                      <Form.Control
                        aria-label="Default"
                        aria-describedby="inputGroup-sizing-default"
                        placeholder="Address"
                      />
                    </InputGroup>
                  </div>
                  <Card>
                    <Card.Body>
                      <div className="crdbdymn">
                        <div className="row">
                          <div className="d-flex justify-content-between px-3">
                            <p className="gray-color">Created 20 hours ago</p>
                            <IoIosSettings />
                          </div>
                          <Col xs={6} className="pe-md-0 d-flex">
                            <div className="mncrd">
                              <div className="crdhdr">
                                <PiAirplaneTakeoffFill />
                                From
                              </div>
                              <div className="crdbdy">
                                <p>New Delhi</p>
                                <div className="crdftr">
                                  <img
                                    src={require("../../Assets/Images/india-flag.webp")}
                                    alt="logo1"
                                  />
                                  india
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col xs={6} className="d-flex">
                            <div className="mncrd">
                              <div className="crdhdr">
                                <PiAirplaneLandingFill />
                                To
                              </div>
                              <div className="crdbdy">
                                <p>Lyon</p>
                                <div className="crdftr">
                                  <img
                                    src={require("../../Assets/Images/France-flag.webp")}
                                    alt="logo1"
                                  />
                                  France
                                </div>
                              </div>
                            </div>
                          </Col>
                        </div>
                      </div>
                      <div className="ftrdv mb-4">
                        <FaCalendarAlt />
                        Travel Date: <span>23 December,2023</span>
                      </div>
                      <div className="ftrdvdwn mb-4">
                        <BsFillSuitcase2Fill />
                        Max Weight: <span>10 Kg</span>
                      </div>
                      <div className="dashboard">
                        <h5>Parcel Description</h5>
                        <Row>
                          <Col xs={4} className="d-flex">
                            <div className="dashboard-img">
                              <img
                                src={require("../../Assets/Images/phone02.webp")}
                                alt="logo1"
                                className="phone-img"
                              />
                            </div>
                          </Col>
                          <Col xs={8}>
                            <Form>
                              <Form.Control
                                as="textarea"
                                style={{ height: "70px" }}
                                name="comment"
                                placeholder="iPhone"
                              />
                            </Form>
                          </Col>
                        </Row>
                      </div>
                      <div className="lsttrvl">
                        Parcels for Travelers: 2
                        <a href="#">
                          Show <IoIosArrowForward />{" "}
                        </a>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </Col>
            <Col md={8}>
              <div className="bgwhite">
                <h3 className="c-heading text-center hdmrgn">
                  From Where, To Where And When To Deliver
                </h3>
                <Form className="mb-5">
                  <Row className="align-items-center justify-content-center">
                    <Col sm={3}>
                      <div className="svgicn mb-3 mb-md-0">
                        <PiAirplaneTakeoffFill />
                        From where*
                      </div>
                    </Col>
                    <Col sm={9}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control
                          type="email"
                          placeholder="Indira Gandhi International  (DEL), New Delhi, India"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <div className="svgicn mb-3 mb-md-0">
                        <PiAirplaneLandingFill />
                        To where*
                      </div>
                    </Col>
                    <Col sm={9}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control type="email" placeholder="Lyon, France" />
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <div className="svgicn mb-3 mb-md-0">
                        <FaCalendarAlt />
                        When
                      </div>
                    </Col>
                    <Col sm={9}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control type="date" placeholder="01/01/2024" />
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <div className="svgicn mb-3 mb-md-0">
                        <BsFillSuitcase2Fill />
                        Max Weight, kg*
                      </div>
                    </Col>
                    <Col sm={7}>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Control type="email" placeholder="10" />
                      </Form.Group>
                    </Col>
                    <Col sm={2} className="mt-3 mt-md-0">
                      <Dropdown>
                        <Dropdown.Toggle
                          id="dropdown-basic"
                          className="drop-kg"
                        >
                          Kg
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item href="#">10</Dropdown.Item>
                          <Dropdown.Item href="#">20</Dropdown.Item>
                          <Dropdown.Item href="#">30</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>

                    <h3 className="c-heading my-5 text-center">
                      Add Recipient's Person
                    </h3>

                    <Col sm={3}>
                      <div className="svgicn mb-3 mb-md-0">
                        <FaCalendarAlt />
                        Name*
                      </div>
                    </Col>
                    <Col sm={9}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control
                          type="text"
                          placeholder="Enter Recipient Name"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <div className="svgicn mb-3 mb-md-0">
                        <IoIosPhonePortrait />
                        Phone Number*
                      </div>
                    </Col>
                    <Col sm={9}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control
                          type="tel"
                          placeholder="Enter Recipient Phone Number"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>

                <div className="dashboard">
                  <Row className="g-3 justify-content-center">
                    <h3 className="c-heading text-center">
                      Take Or Uploade Photo Of You Parcel
                    </h3>
                    <Col xs={12} lg={7}>
                      <p className="mb-5">
                        The photo must show the contents of the package. You can
                        upload a maximum of 3 photos*
                      </p>
                    </Col>
                    <Col xs={9}>
                      <Row>
                        <Col xs={3}>
                          <div className="dashboard-img me-3">
                            <img
                              src={require("../../Assets/Images/camera.webp")}
                              alt="logo1"
                              className="phone-img"
                            />
                          </div>
                        </Col>
                        <Col xs={3}>
                          <div className="dashboard-img">
                            <img
                              src={require("../../Assets/Images/phone01.webp")}
                              alt="logo1"
                              className="phone-img"
                            />
                          </div>
                        </Col>
                        <Col xs={3}>
                          <div className="dashboard-img">
                            <img
                              src={require("../../Assets/Images/phone02.webp")}
                              alt="logo1"
                              className="phone-img"
                            />
                          </div>
                        </Col>
                        <Col xs={3}>
                          <div className="dashboard-img">
                            <img
                              src={require("../../Assets/Images/phone02.webp")}
                              alt="logo1"
                              className="phone-img"
                            />
                          </div>
                        </Col>
                      </Row>
                    </Col>

                    <Col xs={11}>
                      <Form>
                        <Form.Control
                          as="textarea"
                          name="comment"
                          placeholder="Describe the package. What should be transferred, how packed"
                          className="txtara"
                        />
                      </Form>
                    </Col>
                  </Row>
                </div>
                <Row className="justify-content-center">
                  <Col xs={3}>
                    <a class="last-btn c-btn" href="#">
                      Save
                    </a>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Dashboard;
