import { React, useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { IoIosSend } from "react-icons/io";
import { Link } from "react-router-dom";

function ContactForm() {
  const [validated, setValidated] = useState(false);
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
  };
  return (
    <>
      <Form
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
        className="contact-form"
      >
        <Row className="mt-5">
          <Form.Group as={Col} md="6" controlId="validationCustom01">
            <Form.Label>Your Name</Form.Label>
            <Form.Control required type="text" />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="validationCustom02">
            <Form.Label>E-Mail ID</Form.Label>
            <Form.Control required type="email" />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="validationCustom03">
            <Form.Label>Phone No.</Form.Label>
            <Form.Control required type="number" />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="validationCustom04">
            <Form.Label>Subject</Form.Label>
            <Form.Control required type="text" />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} md="12" controlId="validationCustom05">
            <Form.Label>Your Query</Form.Label>
            <Form.Control
              as="textarea"
              style={{ height: "120px", marginBottom:"3rem" }}
              name="comment"
            />
          </Form.Group>
        </Row>
        <Link to="/" className="send-btn">
          Send Message
          <IoIosSend />
        </Link>
      </Form>
    </>
  );
}

export default ContactForm;
