
import { Actions } from "../Actions/Actions";

const initialState = {
  isAuthenticated: false,
  token: "",
  sender: {},
  traveler: {},

};


export default function authenticationReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case Actions.SENDERLOGIN:
      return {
        ...state,
        isAuthenticated: true,
        sender: action.data.sender,

      };
    case Actions.SENDERLOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        sender: {},
        token: "",
      };
    default:
      return {
        ...state,
      }
  }
}
