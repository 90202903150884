import React from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

function Work() {
  return (
    <>
      <div className="banner-inner">
        <img
          src={require("../../Assets/Images/works-banner.webp")}
          alt="logo1"
        />
        <div className="banner-text">
          <h3 className="c-heading">How It Works</h3>
          <p>Connect your sender to your traveler in just a click</p>
        </div>
      </div>

      <main className="middle contact-page">
        <section className="pt-3">
          <div className="container">
            <Breadcrumb>
              <Breadcrumb.Item>Welcome</Breadcrumb.Item>
              <Breadcrumb.Item active>How it Works</Breadcrumb.Item>
            </Breadcrumb>
            <h2 className="c-heading sec-padd-t text-center">
              How Social Shipping Works
            </h2>
            <Row className="g-5 pt-5 sec-padd-b">
              <Col md={4}>
                <div className="work-box">
                  <img
                    src={require("../../Assets/Images/home-img02.webp")}
                    alt="logo2"
                  />
                  <h4>
                    Find a matching <br />
                    traveler or sender
                  </h4>
                  <p>
                    Social Shipping matches travelers with extra luggage space
                    to senders who need to send packages along the same route.
                  </p>
                </div>
              </Col>
              <Col md={4}>
                <div className="work-box">
                  <img
                    src={require("../../Assets/Images/home-img03.webp")}
                    alt="logo2"
                  />
                  <h4>
                    Agree on <br />a delivery fee
                  </h4>
                  <p>
                    Traveler and sender negotiate a delivery fee using Social
                    Shipping. Payment information is provided to Social Shipping
                    as collateral.
                  </p>
                </div>
              </Col>
              <Col md={4}>
                <div className="work-box">
                  <img
                    src={require("../../Assets/Images/home-img01.webp")}
                    alt="logo2"
                  />
                  <h4>Deliver</h4>
                  <p>
                    Traveler carries package to destination and gets
                    confirmation code from recipient. Social Shipping transfers
                    payment to traveler on successful confirmation of delivery.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </section>
        <section className="sec-padd work-banner">
          <div className="container">
            <Row className="work-box rounded-0 border-0">
              <Tabs
                defaultActiveKey="first"
                className="align-items-center justify-content-center d-flex"
              >
                <Tab eventKey="first" title="SENDER">
                  <h3 className="c-heading">Share Package Details</h3>
                  <p className="mt-0">
                    Add details of the package including contents, weight etc.
                    and post your request
                  </p>
                  <h3 className="c-heading">Receive Offers</h3>
                  <p className="mt-0">
                    Once posted all travelers along your route receive a
                    notification of your request and can choose to get in touch
                    with you. Details of all travelers interested in carrying
                    your package are shown to you for your approval
                  </p>
                  <h3 className="c-heading">Approve & Pay</h3>
                  <p className="mt-0">
                    Estimates the charges according to the provided details. To
                    approve any traveler you can complete the payment
                  </p>
                  <h3 className="c-heading">Chat With Traveler</h3>
                  <p className="mt-0">
                    You can chat with the traveler at any time on our website
                    regarding the delivery
                  </p>
                  <h3 className="c-heading">Receive Your Item</h3>
                  <p className="mt-0">
                    The product is then delivered to You can either choose to
                    pick up the package yourself from a place mutually decided
                    with the traveler
                  </p>
                </Tab>
                <Tab eventKey="second" title="TRAVELER">
                  Hii, I am 2nd tab content
                </Tab>
              </Tabs>
            </Row>
          </div>
        </section>
      </main>
    </>
  );
}

export default Work;
