import { useState } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import { FaFacebookF } from "react-icons/fa6";
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa6";
import { BsGlobe } from "react-icons/bs";
import { NavLink } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Register from "../Register/Register";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Login from "../Form/Login";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Actions } from "../../redux/Actions/Actions";
import { FaPowerOff } from 'react-icons/fa';
// import Register from "../Register/Register";
// import LocationTracker from './LocationChecker';

function Header() {
  const state = useSelector((state) => state);
  const { auth } = state; 
  let navigate = useNavigate();
  const dispatch = useDispatch(); 
  function Logout() {
    dispatch({
      type: Actions.SENDERLOGOUT,
    });
    localStorage.clear();
    sessionStorage.clear();
    navigate("/");
  }

  console.log(auth)

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  const [showFirstModal, setShowFirstModal] = useState(false);
  const [showSecondModal, setShowSecondModal] = useState(false);

  const handleFirstModalOpen = () => setShowFirstModal(true);

  const handleFirstModalClose = () => setShowFirstModal(false);

  const handleSecondModalClose = () => setShowSecondModal(false);
  // const handleSecondModalOpen = () => setShowSecondModal(true);
  const handleSecondModalOpen = () => {
    setShowFirstModal(false);
    setShowSecondModal(true);
  };

  return (
    <>
      <header>
        <div className="top-header">
          <div className="container">
            <div className="row">
              <div className="col-md-3">
                <div className="mobile-hide">
                  <NavLink exact to="/">
                    <img
                      src={require("../../Assets/Images/SS-Logo.webp")}
                      className="kri-logo img-fluid pt-2"
                      alt="logo"
                    />
                  </NavLink>
                </div>
              </div>
              <div className="col-md-9 d-flex align-items-center justify-content-end">
                <div className="top-nav-right d-none d-md-block">
                  <ul>
                    <li className="mobile-hide1">Follow Us:</li>
                    <li>
                      <Link
                        href="#"
                        className="facebook"
                        target="_blank"
                        aria-label="facebook"
                      >
                        <FaFacebookF />
                      </Link>
                    </li>
                    <li>
                      <Link
                        href="#"
                        className="facebook"
                        target="_blank"
                        aria-label="facebook"
                      >
                        <FaXTwitter />
                      </Link>
                    </li>
                    <li>
                      <Link
                        href="#"
                        className="facebook"
                        target="_blank"
                        aria-label="facebook"
                      >
                        <FaLinkedinIn />
                      </Link>
                    </li>
                    <li>
                      <Link
                        href="#"
                        className="facebook"
                        target="_blank"
                        aria-label="facebook"
                      >
                        <FaInstagram />
                      </Link>
                    </li>
                    <li>
                      <Link
                        href="#"
                        className="facebook"
                        target="_blank"
                        aria-label="facebook"
                      >
                        <FaYoutube />
                      </Link>
                    </li>
                    <li>
                      <BsGlobe />
                      <select>
                        <option>EN</option>
                        <option>HI</option>
                        <option>GE</option>
                        <option>PE</option>
                        <option>NG</option>
                      </select>
                    </li>
                    {   !auth.isAuthenticated  && 
                    <Link
                      to="/"
                      className="white-btn c-btn"
                      onClick={handleFirstModalOpen}
                    >
                      <img
                        src={require("../../Assets/Images/login.webp")}
                        alt="logo1"
                      />
                      Login / Register
                    </Link>}{   auth.isAuthenticated  &&   <Nav className="ms-auto" >
                <a style={{cursor:'pointer',color:'#FEEAA6'}}>
                  <FaPowerOff size={20} onClick={Logout} />
                </a>
              </Nav>}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="kri-nav mt-3">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="mobile-header">
                  <div className="m-show">
                    <NavLink exact to="/">
                      <img
                        src={require("../../Assets/Images/SS-Logo.webp")}
                        className="kri-logo img-fluid pt-2"
                        alt="logo"
                      />
                    </NavLink>
                  </div>
                  <Navbar expand="lg">
                    <Container fluid>
                      <Navbar.Toggle aria-controls="navbarScroll" />
                      <Navbar.Collapse id="navbarScroll">
                        <Nav navbarScroll>
                          <NavLink
                            className="nav-link"
                            to="/"
                            onClick={scrollToTop}
                          >
                            Welcome
                          </NavLink>
                          <NavLink
                            className="nav-link"
                            to="/sender"
                            onClick={scrollToTop}
                          >
                            {" "}
                            For Senders
                          </NavLink>
                          <NavLink
                            className="nav-link"
                            to="/traveler"
                            onClick={scrollToTop}
                          >
                            {" "}
                            For Travelers
                          </NavLink>
                          <NavLink
                            className="nav-link"
                            to="/how-it-works"
                            onClick={scrollToTop}
                          >
                            How it Works
                          </NavLink>
                          <NavLink
                            className="nav-link"
                            to="/about-us"
                            onClick={scrollToTop}
                          >
                            About Us
                          </NavLink>
                          <NavLink
                            className="nav-link"
                            to="/help"
                            onClick={scrollToTop}
                          >
                            Help
                          </NavLink>
                          <NavLink
                            className="nav-link"
                            to="/contact-us"
                            onClick={scrollToTop}
                          >
                            Contact Us
                          </NavLink>
                          <div className="kri-topbar-right d-block d-md-none mobile-set">
                            <ul>
                              <li className="mobile-hide1">Follow Us:</li>
                              <li>
                                <Link
                                  to="#"
                                  className="facebook"
                                  target="_blank"
                                  aria-label="facebook"
                                >
                                  <FaFacebookF />
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="#"
                                  className="facebook"
                                  target="_blank"
                                  aria-label="facebook"
                                >
                                  <FaXTwitter />
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="#"
                                  className="facebook"
                                  target="_blank"
                                  aria-label="facebook"
                                >
                                  <FaLinkedinIn />
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="#"
                                  className="facebook"
                                  target="_blank"
                                  aria-label="facebook"
                                >
                                  <FaInstagram />
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="#"
                                  className="facebook"
                                  target="_blank"
                                  aria-label="facebook"
                                >
                                  <FaYoutube />
                                </Link>
                              </li>
                              <li>
                                <BsGlobe />
                                <select>
                                  <option>EN</option>
                                  <option>EN</option>
                                  <option>EN</option>
                                  <option>EN</option>
                                  <option>EN</option>
                                </select>
                              </li>
                            </ul>
                          </div>
                        </Nav>
                      </Navbar.Collapse>
                    </Container>
                  </Navbar>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* <LocationTracker /> */}
      <Modal
        show={showFirstModal}
        onHide={handleFirstModalClose}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
           <Login handleSecondModalOpen={handleSecondModalOpen} handleSecondModalClose={handleSecondModalClose}/>
        </Modal.Body>
      </Modal>
      <Modal
        show={showSecondModal}
        onHide={handleSecondModalClose}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Register</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Register />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Header;
