import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { PiAirplaneTakeoffFill } from "react-icons/pi";
import { MdDateRange } from "react-icons/md";
import { PiAirplaneLandingFill } from "react-icons/pi";
import { IoMdSearch } from "react-icons/io";
import Card from "react-bootstrap/Card";
import { CiGift } from "react-icons/ci";
import { IoWalletOutline } from "react-icons/io5";
import Accordion from "react-bootstrap/Accordion";
import { BsUmbrella } from "react-icons/bs";
import { RxDoubleArrowDown } from "react-icons/rx";
import { FaCalendarAlt } from "react-icons/fa";
import { BsFillSuitcase2Fill } from "react-icons/bs";
import { Link } from "react-router-dom";

function Home() {
  return (
    <>
      <section className=" home-banner">
        <Container fluid className=" p-0 position-relative">
          <img
            src={require("../../Assets/Images/home-banner.webp")}
            alt="logo1"
          />
          <Container>
            <Row>
              <Col xs={6}>
                <div className="banner-text">
                  <h1>
                    Send parcels
                    <br />
                    with travellers
                  </h1>

                  <p>
                    Social Shipping is a social network that connecting
                    travelers who have extra luggage space to people with
                    shipping. Senders can buy all their needs from all around
                    the world.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
          <Container className="booking-area">
            <Row className="g-4">
              <Col md={3}>
                <form>
                  <label>
                    <PiAirplaneTakeoffFill />
                    Departure City
                  </label>
                  <input
                    type="text"
                    placeholder="city, distirct or specific airport"
                    className="form-control mt-3"
                  />
                </form>
              </Col>
              <Col md={3}>
                <form>
                  <label>
                    <PiAirplaneLandingFill />
                    Arrival City
                  </label>
                  <input
                    type="text"
                    placeholder="city, distirct or specific airport"
                    className="form-control mt-3"
                  />
                </form>
              </Col>
              <Col md={3}>
                <form>
                  <label>
                    <MdDateRange />
                    Date
                  </label>

                  <input type="date" className="form-control mt-3" />
                </form>
              </Col>
              <Col md={3}>
                <label></label>
                <Link
                  to="/"
                  type="submit"
                  className="main-btn rounded-2 px-lg-3"
                >
                  <IoMdSearch />
                  SEARCH
                </Link>
              </Col>
            </Row>
          </Container>
        </Container>
      </section>

      <section className="sec-padd">
        <Container>
          <div className="filter-icon">
            <h2 className="c-heading text-center">Upcoming Travelers Trips</h2>
            <img
              src={require("../../Assets/Images/filter.svg").default}
              alt="logo1"
            />
          </div>

          <Row className="align-items-center g-5">
            <Col md={4}>
              <Card>
                <div className="card1">
                  <Card.Img
                    variant="top"
                    src={require("../../Assets/Images/aman.webp")}
                    alt="logo1"
                  />
                  <div className="card-text">
                    <h6>Aman Diwakar</h6>
                    <p>Created 20 hours ago</p>
                  </div>
                </div>

                <Card.Body>
                  <div className="crdbdymn">
                    <div className="row">
                      <Col xs={6} className="pe-md-0 d-flex">
                        <div className="mncrd">
                          <div className="crdhdr">
                            <PiAirplaneTakeoffFill />
                            From
                          </div>
                          <div className="crdbdy">
                            <p>New Delhi</p>
                            <div className="crdftr">
                              <img
                                src={require("../../Assets/Images/india-flag.webp")}
                                alt="logo1"
                              />
                              india
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col xs={6} className="d-flex">
                        <div className="mncrd">
                          <div className="crdhdr">
                            <PiAirplaneLandingFill />
                            To
                          </div>
                          <div className="crdbdy">
                            <p>Lyon</p>
                            <div className="crdftr">
                              <img
                                src={require("../../Assets/Images/France-flag.webp")}
                                alt="logo1"
                              />
                              France
                            </div>
                          </div>
                        </div>
                      </Col>
                    </div>
                  </div>

                  <div className="ftrdv mb-4">
                    <FaCalendarAlt />
                    Travel Date: <span>23 December,2023</span>
                  </div>
                  <div className="ftrdvdwn mb-4">
                    <BsFillSuitcase2Fill />
                    Max Weight: <span>10 Kg</span>
                  </div>
                  <div className="text-center">
                    <Link to="/">offer a parcel</Link>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card>
                <div className="card1">
                  <Card.Img
                    variant="top"
                    src={require("../../Assets/Images/aman.webp")}
                    alt="logo1"
                  />
                  <div className="card-text">
                    <h6>Aman Diwakar</h6>
                    <p>Created 20 hours ago</p>
                  </div>
                </div>

                <Card.Body>
                  <div className="crdbdymn">
                    <div className="row">
                      <Col xs={6} className="pe-md-0 d-flex">
                        <div className="mncrd">
                          <div className="crdhdr">
                            <PiAirplaneTakeoffFill />
                            From
                          </div>
                          <div className="crdbdy">
                            <p>New Delhi</p>
                            <div className="crdftr">
                              <img
                                src={require("../../Assets/Images/india-flag.webp")}
                                alt="logo1"
                              />
                              india
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col xs={6} className="d-flex">
                        <div className="mncrd">
                          <div className="crdhdr">
                            <PiAirplaneLandingFill />
                            To
                          </div>
                          <div className="crdbdy">
                            <p>Lyon</p>
                            <div className="crdftr">
                              <img
                                src={require("../../Assets/Images/France-flag.webp")}
                                alt="logo1"
                              />
                              France
                            </div>
                          </div>
                        </div>
                      </Col>
                    </div>
                  </div>

                  <div className="ftrdv mb-4">
                    <FaCalendarAlt />
                    Travel Date: <span>23 December,2023</span>
                  </div>
                  <div className="ftrdvdwn mb-4">
                    <BsFillSuitcase2Fill />
                    Max Weight: <span>10 Kg</span>
                  </div>
                  <div className="text-center">
                    <Link to="/">offer a parcel</Link>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card>
                <div className="card1">
                  <Card.Img
                    variant="top"
                    src={require("../../Assets/Images/aman.webp")}
                    alt="logo1"
                  />
                  <div className="card-text">
                    <h6>Aman Diwakar</h6>
                    <p>Created 20 hours ago</p>
                  </div>
                </div>

                <Card.Body>
                  <div className="crdbdymn">
                    <div className="row">
                      <Col xs={6} className="pe-md-0 d-flex">
                        <div className="mncrd">
                          <div className="crdhdr">
                            <PiAirplaneTakeoffFill />
                            From
                          </div>
                          <div className="crdbdy">
                            <p>New Delhi</p>
                            <div className="crdftr">
                              <img
                                src={require("../../Assets/Images/india-flag.webp")}
                                alt="logo1"
                              />
                              india
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col xs={6} className="d-flex">
                        <div className="mncrd">
                          <div className="crdhdr">
                            <PiAirplaneLandingFill />
                            To
                          </div>
                          <div className="crdbdy">
                            <p>Lyon</p>
                            <div className="crdftr">
                              <img
                                src={require("../../Assets/Images/France-flag.webp")}
                                alt="logo1"
                              />
                              France
                            </div>
                          </div>
                        </div>
                      </Col>
                    </div>
                  </div>

                  <div className="ftrdv mb-4">
                    <FaCalendarAlt />
                    Travel Date: <span>23 December,2023</span>
                  </div>
                  <div className="ftrdvdwn mb-4">
                    <BsFillSuitcase2Fill />
                    Max Weight: <span>10 Kg</span>
                  </div>
                  <div className="text-center">
                    <Link to="/">offer a parcel</Link>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card>
                <div className="card1">
                  <Card.Img
                    variant="top"
                    src={require("../../Assets/Images/aman.webp")}
                    alt="logo1"
                  />
                  <div className="card-text">
                    <h6>Aman Diwakar</h6>
                    <p>Created 20 hours ago</p>
                  </div>
                </div>

                <Card.Body>
                  <div className="crdbdymn">
                    <div className="row">
                      <Col xs={6} className="pe-md-0 d-flex">
                        <div className="mncrd">
                          <div className="crdhdr">
                            <PiAirplaneTakeoffFill />
                            From
                          </div>
                          <div className="crdbdy">
                            <p>New Delhi</p>
                            <div className="crdftr">
                              <img
                                src={require("../../Assets/Images/india-flag.webp")}
                                alt="logo1"
                              />
                              india
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col xs={6} className="d-flex">
                        <div className="mncrd">
                          <div className="crdhdr">
                            <PiAirplaneLandingFill />
                            To
                          </div>
                          <div className="crdbdy">
                            <p>Lyon</p>
                            <div className="crdftr">
                              <img
                                src={require("../../Assets/Images/France-flag.webp")}
                                alt="logo1"
                              />
                              France
                            </div>
                          </div>
                        </div>
                      </Col>
                    </div>
                  </div>

                  <div className="ftrdv mb-4">
                    <FaCalendarAlt />
                    Travel Date: <span>23 December,2023</span>
                  </div>
                  <div className="ftrdvdwn mb-4">
                    <BsFillSuitcase2Fill />
                    Max Weight: <span>10 Kg</span>
                  </div>
                  <div className="text-center">
                    <Link to="/">offer a parcel</Link>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card>
                <div className="card1">
                  <Card.Img
                    variant="top"
                    src={require("../../Assets/Images/aman.webp")}
                    alt="logo1"
                  />
                  <div className="card-text">
                    <h6>Aman Diwakar</h6>
                    <p>Created 20 hours ago</p>
                  </div>
                </div>

                <Card.Body>
                  <div className="crdbdymn">
                    <div className="row">
                      <Col xs={6} className="pe-md-0 d-flex">
                        <div className="mncrd">
                          <div className="crdhdr">
                            <PiAirplaneTakeoffFill />
                            From
                          </div>
                          <div className="crdbdy">
                            <p>New Delhi</p>
                            <div className="crdftr">
                              <img
                                src={require("../../Assets/Images/india-flag.webp")}
                                alt="logo1"
                              />
                              india
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col xs={6} className="d-flex">
                        <div className="mncrd">
                          <div className="crdhdr">
                            <PiAirplaneLandingFill />
                            To
                          </div>
                          <div className="crdbdy">
                            <p>Lyon</p>
                            <div className="crdftr">
                              <img
                                src={require("../../Assets/Images/France-flag.webp")}
                                alt="logo1"
                              />
                              France
                            </div>
                          </div>
                        </div>
                      </Col>
                    </div>
                  </div>

                  <div className="ftrdv mb-4">
                    <FaCalendarAlt />
                    Travel Date: <span>23 December,2023</span>
                  </div>
                  <div className="ftrdvdwn mb-4">
                    <BsFillSuitcase2Fill />
                    Max Weight: <span>10 Kg</span>
                  </div>
                  <div className="text-center">
                    <Link to="/">offer a parcel</Link>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card>
                <div className="card1">
                  <Card.Img
                    variant="top"
                    src={require("../../Assets/Images/aman.webp")}
                    alt="logo1"
                  />
                  <div className="card-text">
                    <h6>Aman Diwakar</h6>
                    <p>Created 20 hours ago</p>
                  </div>
                </div>

                <Card.Body>
                  <div className="crdbdymn">
                    <div className="row">
                      <Col xs={6} className="pe-md-0 d-flex">
                        <div className="mncrd">
                          <div className="crdhdr">
                            <PiAirplaneTakeoffFill />
                            From
                          </div>
                          <div className="crdbdy">
                            <p>New Delhi</p>
                            <div className="crdftr">
                              <img
                                src={require("../../Assets/Images/india-flag.webp")}
                                alt="logo1"
                              />
                              india
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col xs={6} className="d-flex">
                        <div className="mncrd">
                          <div className="crdhdr">
                            <PiAirplaneLandingFill />
                            To
                          </div>
                          <div className="crdbdy">
                            <p>Lyon</p>
                            <div className="crdftr">
                              <img
                                src={require("../../Assets/Images/France-flag.webp")}
                                alt="logo1"
                              />
                              France
                            </div>
                          </div>
                        </div>
                      </Col>
                    </div>
                  </div>

                  <div className="ftrdv mb-4">
                    <FaCalendarAlt />
                    Travel Date: <span>23 December,2023</span>
                  </div>
                  <div className="ftrdvdwn mb-4">
                    <BsFillSuitcase2Fill />
                    Max Weight: <span>10 Kg</span>
                  </div>
                  <div className="text-center">
                    <Link to="/">offer a parcel</Link>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card>
                <div className="card1">
                  <Card.Img
                    variant="top"
                    src={require("../../Assets/Images/aman.webp")}
                    alt="logo1"
                  />
                  <div className="card-text">
                    <h6>Aman Diwakar</h6>
                    <p>Created 20 hours ago</p>
                  </div>
                </div>

                <Card.Body>
                  <div className="crdbdymn">
                    <div className="row">
                      <Col xs={6} className="pe-md-0 d-flex">
                        <div className="mncrd">
                          <div className="crdhdr">
                            <PiAirplaneTakeoffFill />
                            From
                          </div>
                          <div className="crdbdy">
                            <p>New Delhi</p>
                            <div className="crdftr">
                              <img
                                src={require("../../Assets/Images/india-flag.webp")}
                                alt="logo1"
                              />
                              india
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col xs={6} className="d-flex">
                        <div className="mncrd">
                          <div className="crdhdr">
                            <PiAirplaneLandingFill />
                            To
                          </div>
                          <div className="crdbdy">
                            <p>Lyon</p>
                            <div className="crdftr">
                              <img
                                src={require("../../Assets/Images/France-flag.webp")}
                                alt="logo1"
                              />
                              France
                            </div>
                          </div>
                        </div>
                      </Col>
                    </div>
                  </div>

                  <div className="ftrdv mb-4">
                    <FaCalendarAlt />
                    Travel Date: <span>23 December,2023</span>
                  </div>
                  <div className="ftrdvdwn mb-4">
                    <BsFillSuitcase2Fill />
                    Max Weight: <span>10 Kg</span>
                  </div>
                  <div className="text-center">
                    <Link to="/">offer a parcel</Link>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card>
                <div className="card1">
                  <Card.Img
                    variant="top"
                    src={require("../../Assets/Images/aman.webp")}
                    alt="logo1"
                  />
                  <div className="card-text">
                    <h6>Aman Diwakar</h6>
                    <p>Created 20 hours ago</p>
                  </div>
                </div>

                <Card.Body>
                  <div className="crdbdymn">
                    <div className="row">
                      <Col xs={6} className="pe-md-0 d-flex">
                        <div className="mncrd">
                          <div className="crdhdr">
                            <PiAirplaneTakeoffFill />
                            From
                          </div>
                          <div className="crdbdy">
                            <p>New Delhi</p>
                            <div className="crdftr">
                              <img
                                src={require("../../Assets/Images/india-flag.webp")}
                                alt="logo1"
                              />
                              india
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col xs={6} className="d-flex">
                        <div className="mncrd">
                          <div className="crdhdr">
                            <PiAirplaneLandingFill />
                            To
                          </div>
                          <div className="crdbdy">
                            <p>Lyon</p>
                            <div className="crdftr">
                              <img
                                src={require("../../Assets/Images/France-flag.webp")}
                                alt="logo1"
                              />
                              France
                            </div>
                          </div>
                        </div>
                      </Col>
                    </div>
                  </div>

                  <div className="ftrdv mb-4">
                    <FaCalendarAlt />
                    Travel Date: <span>23 December,2023</span>
                  </div>
                  <div className="ftrdvdwn mb-4">
                    <BsFillSuitcase2Fill />
                    Max Weight: <span>10 Kg</span>
                  </div>
                  <div className="text-center">
                    <Link to="/">offer a parcel</Link>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card>
                <div className="card1">
                  <Card.Img
                    variant="top"
                    src={require("../../Assets/Images/aman.webp")}
                    alt="logo1"
                  />
                  <div className="card-text">
                    <h6>Aman Diwakar</h6>
                    <p>Created 20 hours ago</p>
                  </div>
                </div>

                <Card.Body>
                  <div className="crdbdymn">
                    <div className="row">
                      <Col xs={6} className="pe-md-0 d-flex">
                        <div className="mncrd">
                          <div className="crdhdr">
                            <PiAirplaneTakeoffFill />
                            From
                          </div>
                          <div className="crdbdy">
                            <p>New Delhi</p>
                            <div className="crdftr">
                              <img
                                src={require("../../Assets/Images/india-flag.webp")}
                                alt="logo1"
                              />
                              india
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col xs={6} className="d-flex">
                        <div className="mncrd">
                          <div className="crdhdr">
                            <PiAirplaneLandingFill />
                            To
                          </div>
                          <div className="crdbdy">
                            <p>Lyon</p>
                            <div className="crdftr">
                              <img
                                src={require("../../Assets/Images/France-flag.webp")}
                                alt="logo1"
                              />
                              France
                            </div>
                          </div>
                        </div>
                      </Col>
                    </div>
                  </div>

                  <div className="ftrdv mb-4">
                    <FaCalendarAlt />
                    Travel Date: <span>23 December,2023</span>
                  </div>
                  <div className="ftrdvdwn mb-4">
                    <BsFillSuitcase2Fill />
                    Max Weight: <span>10 Kg</span>
                  </div>
                  <div className="text-center">
                    <Link to="/">offer a parcel</Link>
                  </div>
                </Card.Body>
              </Card>
            </Col>

            <div className=" d-flex align-items-center justify-content-center mt-5">
              <Link to="/" className="view-btn">
                <RxDoubleArrowDown />
                View All
              </Link>
            </div>
          </Row>
        </Container>
      </section>

      <section className="sec-padd middle">
        <Container>
          <h3 className="c-heading text-center mb-5">How It Works</h3>
          <Row className="g-5">
            <Col md={4}>
              <div className="work-box">
                <img
                  src={require("../../Assets/Images/home-img02.webp")}
                  alt="logo2"
                />
                <h4>
                  Find a matching <br />
                  traveler or sender
                </h4>
                <p>
                  Social Shipping matches travelers with extra luggage space to
                  senders who need to send packages along the same route.
                </p>
              </div>
            </Col>
            <Col md={4}>
              <div className="work-box">
                <img
                  src={require("../../Assets/Images/home-img03.webp")}
                  alt="logo2"
                />
                <h4>
                  Agree on <br />a delivery fee
                </h4>
                <p>
                  Traveler and sender negotiate a delivery fee using Social
                  Shipping. Payment information is provided to Social Shipping
                  as collateral.
                </p>
              </div>
            </Col>
            <Col md={4}>
              <div className="work-box">
                <img
                  src={require("../../Assets/Images/home-img01.webp")}
                  alt="logo2"
                />
                <h4>Deliver</h4>
                <p>
                  Traveler carries package to destination and gets confirmation
                  code from recipient. Social Shipping transfers payment to
                  traveler on successful confirmation of delivery.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="sec-padd">
        <Container>
          <h3 className="c-heading text-center">FAQ For Senders</h3>
          <p className="text-center mb-5 gray-color">
            How to send parcels with Social Shipping
          </p>
          <Row className="align-items-center justify-content-center">
            <Col md={12}>
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <CiGift />
                    What can I use Social Shipping for?
                  </Accordion.Header>
                  <Accordion.Body>
                    Shipping - You can use Social Shipping to ship packages to
                    others. Expanded, personalized shipping options to anywhere
                    someone travels. Anytime.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <BsUmbrella />
                    How is my package protected? What if my package gets lost or
                    damaged?
                  </Accordion.Header>
                  <Accordion.Body>
                    Shipping - You can use Social Shipping to ship packages to
                    others. Expanded, personalized shipping options to anywhere
                    someone travels. Anytime.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    <IoWalletOutline />
                    How do I pay a traveler for delivering my package?
                  </Accordion.Header>
                  <Accordion.Body>
                    Shipping - You can use Social Shipping to ship packages to
                    others. Expanded, personalized shipping options to anywhere
                    someone travels. Anytime.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="sec-padd pt-0">
        <Container>
          <h3 className="c-heading text-center">FAQ For Travelers</h3>
          <p className="text-center mb-5">
            How to deliver parcels with Social Shipping and make money
          </p>
          <Row className="align-items-center justify-content-center">
            <Col md={12}>
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <CiGift />
                    How do I make sure packages are safe to carry?
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Travelers should use discretion in choosing packages to
                      carry.
                    </p>
                    <p>
                      Do not carry sealed packages without first inspecting
                      them. After inspection at pickup, only agree to carry
                      packages you're comfortable with and politely decline all
                      others.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <BsUmbrella />
                    Is it legal to carry packages for others?
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Travelers should use discretion in choosing packages to
                      carry.
                    </p>
                    <p>
                      Do not carry sealed packages without first inspecting
                      them. After inspection at pickup, only agree to carry
                      packages you're comfortable with and politely decline all
                      others.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    <IoWalletOutline />
                    How do I get paid for delivering a package?
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Travelers should use discretion in choosing packages to
                      carry.
                    </p>
                    <p>
                      Do not carry sealed packages without first inspecting
                      them. After inspection at pickup, only agree to carry
                      packages you're comfortable with and politely decline all
                      others.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Home;
