export const endPoints = { 

     baseUrl: 'http://localhost:8000/',  
     apiBaseUrl: 'http://localhost:8000/api',  
  
    api: {
      TravelerLogin:"auth/traveler/login",
      SenderLogin:"auth/sender/login",
      SenderRegister:"auth/sender/register",
      TravelerRegister:"auth/traveler/register",
       
    },
    mode: "Development",
  };
 
//   export const Image_Url = " " 
 
  
  export default {};
  