import React from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";

function Sender() {
  return (
    <>
      <div className="banner-inner">
        <img
          src={require("../../Assets/Images/sender-banner.webp")}
          alt="logo1"
        />
        <div className="banner-text">
          <h3 className="c-heading">For Senders</h3>
          <p>How to send parcels with Social Shipping</p>
        </div>
      </div>

      <main className="middle contact-page sender-page">
        <section className="pt-3">
          <div className="container">
            <Breadcrumb>
              <Breadcrumb.Item>Welcome</Breadcrumb.Item>
              <Breadcrumb.Item active>For Senders</Breadcrumb.Item>
            </Breadcrumb>
            <h2 className="c-heading text-center sec-padd-t">
              I am a <span className="send-span">SENDER</span>
            </h2>
            <p className="gray-color text-center">
              Simply follow these four easy steps
            </p>
            <div className="row gy-4 gx-0 pt-5 sec-padd sender-set">
              <div className="col-md-6">
                <div className="sender-box">
                  <div className="d-flex">
                    <div className="sender-box1">
                      <h5>1</h5>
                    </div>
                    <div className="sender-text">
                      <h4>UPLOAD YOUR ORDER</h4>
                    </div>
                  </div>
                  <p>
                    Add your order details including a link for the traveler to
                    send the item through and the suggested for the traveler.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <img
                  src={require("../../Assets/Images/sender-img01.webp")}
                  alt="logo1"
                />
              </div>
              <div className="col-md-6">
                <img
                  src={require("../../Assets/Images/sender-img02.webp")}
                  alt="logo1"
                />
              </div>
              <div className="col-md-6">
                <div className="sender-box">
                  <div className="d-flex">
                    <div className="sender-box1">
                      <h5>2</h5>
                    </div>
                    <div className="sender-text">
                      <h4>WAIT FOR A DELIVERY OFFER</h4>
                    </div>
                  </div>
                  <p>
                    Travelers will make delivery offers and you can choose the
                    one that suits you best. You can use the chat feature in the
                    website to discuss and agree with the traveler on all the
                    details including the item description and delivery time and
                    location.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="sender-box">
                  <div className="d-flex">
                    <div className="sender-box1">
                      <h5>3</h5>
                    </div>
                    <div className="sender-text">
                      <h4>ACCEPT OFFER AND PAY</h4>
                    </div>
                  </div>
                  <p>
                    Users pay for delivery services without Social Shipping
                    involvement. Choose the payment method when you create a
                    parcel: upon receipt or upon departure.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <img
                  src={require("../../Assets/Images/sender-img03.webp")}
                  alt="logo1"
                />
              </div>
              <div className="col-md-6">
                <img
                  src={require("../../Assets/Images/sender-img04.webp")}
                  alt="logo1"
                />
              </div>
              <div className="col-md-6">
                <div className="sender-box">
                  <div className="d-flex">
                    <div className="sender-box1">
                      <h5>4</h5>
                    </div>
                    <div className="sender-text">
                      <h4>DELIVER YOUR shipping ITEM</h4>
                    </div>
                  </div>
                  <p>
                    Successful delivery of your package when you, or the
                    recipient, provides a delivery confirmation to the traveler.
                  </p>
                </div>
              </div>
              <div class=" d-flex align-items-center justify-content-center mt-5">
                <Link to="/dashboard" class="sender-btn">
                  Save Money For Shipping
                </Link>
              </div>
            </div>
          </div>
        </section>
        <section className="sec-padd middle bg-white sender">
          <Container>
            <h3 className="c-heading text-center mb-5">Benefits For Senders</h3>
            <Row className="g-5">
              <Col md={4}>
                <div className="work-box">
                  <img
                    src={require("../../Assets/Images/sender-img05.webp")}
                    alt="logo2"
                  />
                  <h4>Cheap</h4>
                  <p>
                    Much cheaper than traditional shipping options. You
                    negotiate your own delivery fee.
                  </p>
                </div>
              </Col>
              <Col md={4}>
                <div className="work-box">
                  <img
                    src={require("../../Assets/Images/sender-img06.webp")}
                    alt="logo2"
                  />
                  <h4>Flexible and Fast</h4>
                  <p>
                    Expanded, personalized shipping options to anywhere someone
                    travels. Anytime.
                  </p>
                </div>
              </Col>
              <Col md={4}>
                <div className="work-box">
                  <img
                    src={require("../../Assets/Images/sender-img07.webp")}
                    alt="logo2"
                  />
                  <h4>Protection</h4>
                  <p>
                    Only pay if package is successfully delivered. Get
                    reimbursed if your package is lost or damaged.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    </>
  );
}

export default Sender;
