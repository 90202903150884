import React from "react";
import { Link } from "react-router-dom";
import ContactForm from "../Form/ContactForm";
import Breadcrumb from "react-bootstrap/Breadcrumb";

function Contact() {
  return (
    <>
      <div className="banner-inner">
        <img src={require("../../Assets/Images/contact-us.webp")} alt="logo1" />
        <div className="banner-text">
          <h3 className="c-heading">Get-in Touch</h3>
          <p>
            If you have any questions or suggestions, <br />
            please contact with us
          </p>
        </div>
      </div>

      <main className="middle contact-page">
        <section className="pt-3 sender">
          <div className="container">
            <Breadcrumb>
              <Breadcrumb.Item>Welcome</Breadcrumb.Item>
              <Breadcrumb.Item active>Contact Us</Breadcrumb.Item>
            </Breadcrumb>
            <div className="row g-5 sec-padd-t">
              <div className="col-md-7">
                <h2 className="c-heading">Drop Us a Line</h2>
                <p className="gray-color">
                  Get in touch via form below and we will reply as soos as we
                  can.
                </p>
                <ContactForm />
              </div>
              <div className="col-md-5">
                <div className="work-box h-auto mb-5 p-5">
                  <img
                    src={require("../../Assets/Images/box.webp")}
                    alt="logo2"
                  />
                  <h3>Drop a Mail</h3>
                  <h5>
                    <Link to="mailto:SocialShipping@gmail.com">
                      SocialShipping@gmail.com
                    </Link>
                  </h5>
                  <h5>
                    <Link to="mailto:info.socialshipping@gmail.com">
                      info.socialshipping@gmail.com
                    </Link>
                  </h5>
                </div>
                <div className="work-box h-auto mb-5 p-5">
                  <img
                    src={require("../../Assets/Images/call.webp")}
                    alt="logo2"
                  />
                  <h3>Call Us</h3>
                  <h5>
                    <Link to="tel:(0522)2563568">(0522) 2563568</Link>
                  </h5>
                  <h5>
                    <Link to="tel:+912566548457">+91 256 6548 457</Link>
                  </h5>
                </div>
                <div className="work-box h-auto mb-5 p-5">
                  <img
                    src={require("../../Assets/Images/whatsapp.webp")}
                    alt="logo2"
                  />
                  <h3>Chat with us</h3>
                  <h5>
                    <Link to="https://api.whatsapp.com/send?phone=123456789">
                      Start a conversation on WhatsApp (+123 456 789)
                    </Link>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sec-padd">
          <div className="container">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.579341486233!2d77.20735924710138!3d28.61239388408227!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfd5b347eb62d%3A0x52c2b7494e204dce!2sNew%20Delhi%2C%20Delhi!5e0!3m2!1sen!2sin!4v1703840013952!5m2!1sen!2sin"
              width="100%"
              height="450"
              loading="lazy"
            ></iframe>
          </div>
        </section>
      </main>
    </>
  );
}

export default Contact;
