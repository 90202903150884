import React from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { CiGift } from "react-icons/ci";
import Accordion from "react-bootstrap/Accordion";
import { IoWalletOutline } from "react-icons/io5";
import { BsUmbrella } from "react-icons/bs";
function Help() {
  return (
    <>
      <div className="banner-inner">
        <img
          src={require("../../Assets/Images/help-banner.webp")}
          alt="logo1"
        />
        <div className="banner-text">
          <h3 className="c-heading">How can we help you?</h3>
          <p>
            This is the right place for your questions and concerns. <br /> Our
            FAQ will be happy to help you. Fast, friendly and competent.
          </p>
        </div>
      </div>

      <main className="middle contact-page help-page">
        <section className="pt-3">
          <div className="container">
            <Breadcrumb>
              <Breadcrumb.Item>Welcome</Breadcrumb.Item>
              <Breadcrumb.Item active>Help</Breadcrumb.Item>
            </Breadcrumb>
            <div className="row g-5 sec-padd-t">
              <div className="col-md-3">
                <div className="work-box h-auto mb-5 p-5">
                  <img
                    src={require("../../Assets/Images/help-img01.svg").default}
                    alt="logo1"
                  />
                  <h3>Get Started </h3>
                  <p>
                    {" "}
                    Learn how to set up your account, make offers, and earn
                    money on the shipping needs.{" "}
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="work-box h-auto mb-5 p-5">
                  <img
                    src={require("../../Assets/Images/help-img04.svg").default}
                    alt="logo1"
                  />
                  <h3> General Info </h3>
                  <p>
                    Fast facts about Social Shipping, such as how it works and
                    other frequently asked questions.
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="work-box h-auto mb-5 p-5">
                  <img
                    src={require("../../Assets/Images/help-img03.svg").default}
                    alt="logo1"
                  />
                  <h3> For Sender </h3>
                  <p>
                    Much cheaper than traditional shipping options. You
                    negotiate your own delivery fee.
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="work-box h-auto mb-5 p-5">
                  <img
                    src={require("../../Assets/Images/help-img02.svg").default}
                    alt="logo1"
                  />
                  <h3> For Travelers </h3>
                  <p>
                    Best practices for travelers, make money by carrying
                    packages you're comfortable with.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="sec-padd">
          <Container>
            <h3 className="c-heading text-center">FAQ For Senders</h3>
            <p className="text-center mb-5 gray-color">
              How to send parcels with Social Shipping
            </p>
            <Row className="align-items-center justify-content-center">
              <Col md={12}>
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <CiGift />
                      What can I use Social Shipping for?
                    </Accordion.Header>
                    <Accordion.Body>
                      Shipping - You can use Social Shipping to ship packages to
                      others. Expanded, personalized shipping options to
                      anywhere someone travels. Anytime.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      <BsUmbrella />
                      How is my package protected? What if my package gets lost
                      or damaged?
                    </Accordion.Header>
                    <Accordion.Body>
                      Shipping - You can use Social Shipping to ship packages to
                      others. Expanded, personalized shipping options to
                      anywhere someone travels. Anytime.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      <IoWalletOutline />
                      How do I pay a traveler for delivering my package?
                    </Accordion.Header>
                    <Accordion.Body>
                      Shipping - You can use Social Shipping to ship packages to
                      others. Expanded, personalized shipping options to
                      anywhere someone travels. Anytime.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    </>
  );
}

export default Help;
