import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import Home from "./Components/Home/Home";
import About from "./Components/About/About";
import Contact from "./Components/Contact/Contact";
import Help from "./Components/Help/Help";
import Work from "./Components/Work/Work";
import Sender from "./Components/Sender/Sender";
import Traveler from "./Components/Traveler/Traveler";
import Dashboard from "./Components/Dashboard/Dashboard";
import DashboardTraveler from "./Components/Dashboard/DashboardTraveler";
import { Provider } from "react-redux";
import store from './redux/Store/store'

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route path="" element={<Home />} />
      <Route path="/about-Us" element={<About />} />
      <Route path="/contact-Us" element={<Contact />} />
      <Route path="/help" element={<Help />} />
      <Route path="/traveler" element={<Traveler />} />
      <Route path="/how-it-works" element={<Work />} />
      <Route path="/sender" element={<Sender />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/dashboardTraveler" element={<DashboardTraveler />} />
    </Route>
  )
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    {/* Wrap your entire application with the Provider */}
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
