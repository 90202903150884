import React from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function About() {
  return (
    <>
      <div className="banner-inner">
        <img
          src={require("../../Assets/Images/about-banner.webp")}
          alt="logo1"
        />
        <div className="banner-text">
          <h3 className="c-heading">
            About Social Shipping & <br />
            Our Mission
          </h3>
          <p>Save Money Shipping. Make Money Traveling.</p>
        </div>
      </div>

      <main className="middle contact-page">
        <section className="pt-3">
          <div className="container">
            <Breadcrumb>
              <Breadcrumb.Item>Welcome</Breadcrumb.Item>
              <Breadcrumb.Item active>About Us</Breadcrumb.Item>
            </Breadcrumb>
            <div className="row g-0 sec-padd-t align-items-center">
              <div className="col-md-6">
                <img
                  src={require("../../Assets/Images/about-img01.webp")}
                  alt="logo1"
                />
              </div>
              <div className="col-md-6 about-banner">
                <img
                  src={require("../../Assets/Images/about-img02.webp")}
                  alt="logo2"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="sec-padd pb-0">
          <Container>
            <Row className="justify-content-center">
              <Col md={10}>
                <h3 className="c-heading text-center">
                  Shipping anywhere, traveler carries package to everywhere
                </h3>
                <p className="text-center mb-5 gray-color mt-4">
                  Social Shipping is a social network that connects Senders with
                  Travelers. Connecting travelers who have extra luggage space
                  to people with shipping and ship with a Traveler already
                  heading their way. Senders save money shipping & Travelers
                  make money traveling.
                </p>
              </Col>
            </Row>
            <Row className="align-items-center justify-content-center">
              <Col md={9} lg={8}>
                <Row className="mt-5 mb-5 g-5">
                  <Col md={4}>
                    <div className="d-flex align-items-center">
                      <div className="about-img">
                        <img
                          src={require("../../Assets/Images/about-icon.webp")}
                          alt="logo1"
                        />
                      </div>
                      <div className="about-text">
                        <h4>10K</h4>
                        <p className="m-0">Registered Users</p>
                      </div>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="d-flex align-items-center">
                      <div className="about-img">
                        <img
                          src={require("../../Assets/Images/about-icon01.webp")}
                          alt="logo1"
                        />
                      </div>
                      <div className="about-text">
                        <h4>115K</h4>
                        <p className="m-0">Shipments Posted</p>
                      </div>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="d-flex align-items-center">
                      <div className="about-img">
                        <img
                          src={require("../../Assets/Images/about-icon02.webp")}
                          alt="logo1"
                        />
                      </div>
                      <div className="about-text">
                        <h4>5K</h4>
                        <p className="m-0">Trips Posted</p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="align-items-center justify-content-center mt-5">
              <Col md={10}>
                <img
                  src={require("../../Assets/Images/about-banner01.webp")}
                  alt="logo1"
                />
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    </>
  );
}

export default About;
