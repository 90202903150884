import { React, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { TiSocialFacebook } from "react-icons/ti";
import { FaXTwitter } from "react-icons/fa6";
import { TiSocialLinkedin } from "react-icons/ti";
import { PiInstagramLogoThin } from "react-icons/pi";
import { FaYoutube } from "react-icons/fa";

function Footer() {
  return (
    <>
      <section className="footer-banner">
        <Container>
          <Row className="align-items-center">
            <Col md={6}>
              <img
                src={require("../../Assets/Images/footer-logo.webp")}
                alt="logo1"
              />
            </Col>
            <Col md={6}>
              <div className="footer-follow-us">
                <h4>Follow Us</h4>
                <ul>
                  <li>
                    <a
                      href="#"
                      className="facebook"
                      target="_blank"
                      aria-label="facebook"
                    >
                      <TiSocialFacebook />
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      className="facebook"
                      target="_blank"
                      aria-label="facebook"
                    >
                      <FaXTwitter />
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      className="facebook"
                      target="_blank"
                      aria-label="facebook"
                    >
                      <TiSocialLinkedin />
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      className="facebook"
                      target="_blank"
                      aria-label="facebook"
                    >
                      <PiInstagramLogoThin />
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      className="facebook"
                      target="_blank"
                      aria-label="facebook"
                    >
                      <FaYoutube />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="footer-about">
                <p>Copyright @ 2023 Social Shipping. All rights reserved.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Footer;
