import React from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
function Traveler() {
  return (
    <>
      <div className="banner-inner">
        <img
          src={require("../../Assets/Images/traveler-banner.webp")}
          alt="logo1"
        />
        <div className="banner-text">
          <h3 className="c-heading">For Travelers</h3>
          <p>How to deliver parcels with Social Shipping and make money</p>
        </div>
      </div>

      <main className="middle contact-page">
        <section className="pt-3">
          <div className="container">
            <Breadcrumb>
              <Breadcrumb.Item>Welcome</Breadcrumb.Item>
              <Breadcrumb.Item active>For Travelers</Breadcrumb.Item>
            </Breadcrumb>
            <h2 className="c-heading text-center sec-padd-t">
              I am a <span className="send-span">TRAVELER</span>
            </h2>
            <p className="gray-color text-center">
              Simply follow these four easy steps
            </p>
            <div className="row gy-4 gx-0 pt-5 sec-padd sender-set">
              <div className="col-md-6">
                <div className="sender-box">
                  <div className="d-flex">
                    <div className="sender-box1">
                      <h5>1</h5>
                    </div>
                    <div className="sender-text">
                      <h4>UPLOAD YOUR TRIP AND YOU CAN DELIVER</h4>
                    </div>
                  </div>
                  <p>
                    Once you upload a trip, you can search for items that match
                    with your location and dates and start making delivery
                    offers.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <img
                  src={require("../../Assets/Images/traveler-img01.webp")}
                  alt="logo1"
                />
              </div>
              <div className="col-md-6">
                <img
                  src={require("../../Assets/Images/traveler-img02.webp")}
                  alt="logo1"
                />
              </div>
              <div className="col-md-6">
                <div className="sender-box">
                  <div className="d-flex">
                    <div className="sender-box1">
                      <h5>2</h5>
                    </div>
                    <div className="sender-text">
                      <h4>CONFIRM ORDER DETAILS</h4>
                    </div>
                  </div>
                  <p>
                    You can use the chat feature in the Website to discuss the
                    order details with the sender such as the exact item
                    description and delivery time and place.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="sender-box">
                  <div className="d-flex">
                    <div className="sender-box1">
                      <h5>3</h5>
                    </div>
                    <div className="sender-text">
                      <h4>Payment received THE ITEM</h4>
                    </div>
                  </div>
                  <p>
                    Once the sender has confirmed the deal and paid the agreed
                    amount, you can go ahead the item and start packing it for
                    delivery.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <img
                  src={require("../../Assets/Images/traveler-img03.webp")}
                  alt="logo1"
                />
              </div>
              <div className="col-md-6">
                <img
                  src={require("../../Assets/Images/traveler-img04.webp")}
                  alt="logo1"
                />
              </div>
              <div className="col-md-6">
                <div className="sender-box">
                  <div className="d-flex">
                    <div className="sender-box1">
                      <h5>4</h5>
                    </div>
                    <div className="sender-text">
                      <h4>DELIVER YOUR shipping ITEM</h4>
                    </div>
                  </div>
                  <p>
                    Be sure to get a confirmation from the recipient or sender
                    when you deliver the package.
                  </p>
                </div>
              </div>
              <div class=" d-flex align-items-center justify-content-center mt-5">
                <Link to="/dashboardTraveler" class="sender-btn">
                  Make Money For Traveling
                </Link>
              </div>
            </div>
          </div>
        </section>
        <section className="sec-padd middle bg-white sender">
          <Container>
            <h3 className="c-heading text-center mb-5">
              Benefits For Travelers
            </h3>
            <Row className="g-5">
              <Col md={4}>
                <div className="work-box">
                  <img
                    src={require("../../Assets/Images/traveler-img05.webp")}
                    alt="logo2"
                  />
                  <h4>Make Extra Cash</h4>
                  <p>
                    Make money by carrying packages you're comfortable with.
                  </p>
                </div>
              </Col>
              <Col md={4}>
                <div className="work-box">
                  <img
                    src={require("../../Assets/Images/traveler-img06.webp")}
                    alt="logo2"
                  />
                  <h4>Travel for Free</h4>
                  <p>
                    Want to travel somewhere nice for free? Just find enough
                    packages to deliver to cover the cost of your trip.
                  </p>
                </div>
              </Col>
              <Col md={4}>
                <div className="work-box">
                  <img
                    src={require("../../Assets/Images/traveler-img07.webp")}
                    alt="logo2"
                  />
                  <h4>Help Friends and Family</h4>
                  <p>
                    Make it easy for friends and family to know when you're
                    traveling and can help deliver packages.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    </>
  );
}

export default Traveler;
